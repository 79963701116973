import React from "react"
import { graphql } from "gatsby"
import InnerPage from "../components/innerPage"
import InnerHeader from "../components/InnerHeader"
import Breadcrumb from "../components/breadcrumb"
import { Helmet } from "react-helmet"
const marked = require("marked");

const TemplateBlog = ({data}) => {
  const {title, content, date, image} = data.strapiBlogPages;

  return(
    <InnerPage>
      <Helmet>
        <title>{title}</title>
        <meta name="Description" content={title}/>
      </Helmet>
      <InnerHeader>
        <Breadcrumb label={'Blog'} />
      </InnerHeader>
      <div className="container py3 py140md">
        <div className="row">
          <div className="colMd12">
            <img src={image.publicURL} alt={title} className="imgFluid mb1 dBlock mxAuto mwFull" />
            <p className="textCenter">{date}</p>
            <h2 className="text2md mdText2lg textCenter textSecondaryDark textUppercase fontSecondary mb3 mb5md">{title}</h2>
          </div>
        </div>
        <div dangerouslySetInnerHTML={{__html: marked(content)}} />
      </div>
    </InnerPage>
  )
}

export default TemplateBlog

export const query = graphql`
  query BlogItem($id: String!) {
     strapiBlogPages(id: {eq: $id}) {
      title
      content
      date(formatString: "DD MMMM YYYY")
      image {
        publicURL
      }
    }
  }
`
